import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import { LoadingIndicatorLogo } from '@advisorycloud/react-ui-components';
import { useParams, useHistory } from 'react-router-dom';
import {
  getRecurlyPlanCall,
  updateRecurlyPlanCall,
  getAllRecurlyPlans,
} from '../../services/admin_services';
import Grid from '@mui/material/Grid';

const useStyles = (isSM) => ({
  formField: {
    paddingBottom: '15px',
  },
  checkField: {
    marginBottom: '17px',
  },
  appContainer: {
    padding: '30px 100px',
  },
  header: {
    marginBottom: '15px',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: isSM && 'white',
  },
});

function PlanCodeForm() {
  const classes = useStyles();
  const [recurlyPlanCode, setrecurlyPlanCode] = useState('');
  const [recurlyPlanCodeLabel, setrecurlyPlanCodeLabel] = useState('');
  const [accountType, setaccountType] = useState('');
  const [recurlyPlanCodePrice, setrecurlyPlanCodePrice] = useState(0);
  const [recurlyPlanCodeTerm, setrecurlyPlanCodeTerm] = useState('');
  const [lengthOfTrialInDays, setlengthOfTrialInDays] = useState('');
  const [boardsCanJoinCount, setboardsCanJoinCount] = useState('');
  const [boardSeatsAllowedCount, setboardSeatsAllowedCount] = useState('');
  const [isActive, setisActive] = useState(false);
  const [isPrivate, setisPrivate] = useState(false);
  const [meetingsEnabled, setmeetingsEnabled] = useState(false);
  const [onboardingRedirectUrl, setonboardingRedirectUrl] = useState('');
  const [upgradeUrl, setupgradeUrl] = useState('');
  const [maxBoardsUpgradeUrl, setMaxBoardsUpgradeUrl] = useState('');
  const [specialOffersUrl, setSpecialOffersUrl] = useState('');
  const [checkoutRedirectUrl, setcheckoutRedirectUrl] = useState('');
  const [dualRecurlyAction, setdualRecurlyAction] = useState('');
  const [isDualCapable, setisDualCapable] = useState(false);
  const [isLegacy, setisLegacy] = useState(false);
  const [isFreemium, setisFreemium] = useState(false);
  const [
    earlyConversionAndUpgradePlancode,
    setearlyConversionAndUpgradePlancode,
  ] = useState(false);
  const [freemiumUpgradeOne, setfreemiumUpgradeOne] = useState(false);
  const [freemiumUpgradeTwo, setfreemiumUpgradeTwo] = useState(false);
  const [isAcPlus, setisAcPlus] = useState(false);
  const [acPlusUpgradeOptionOne, setacPlusUpgradeOptionOne] = useState(false);
  const [acPlusUpgradeOptionTwo, setacPlusUpgradeOptionTwo] = useState(false);
  const [b2bFreeTrialMonthlyUpgrade1, setb2bFreeTrialMonthlyUpgrade1] =
    useState(false);
  const [b2bFreeTrialAnnualUpgrade1, setb2bFreeTrialAnnualUpgrade1] =
    useState(false);
  const [b2bFreeTrialMonthlyUpgrade2, setb2bFreeTrialMonthlyUpgrade2] =
    useState(false);
  const [b2bFreeTrialAnnualUpgrade2, setb2bFreeTrialAnnualUpgrade2] =
    useState(false);

  const [b2bOnboardingCalendar, setb2bOnboardingCalendar] = useState('');
  const [isPoeEligible, setisPoeEligible] = useState(false);
  const [isUpgrade, setisUpgrade] = useState(false);
  const [legacyPlanCode, setlegacyPlanCode] = useState('');
  const [recurlyAction, setrecurlyAction] = useState('');
  const [recurlyPlanCodeDisplayName, setrecurlyPlanCodeDisplayName] =
    useState('');
  const [salesforceValues, setsalesforceValues] = useState({});
  const [premierOnboardingExperience, setpremierOnboardingExperience] =
    useState('');
  const [premierOnboardingPurchaseDate, setpremierOnboardingPurchaseDate] =
    useState('');
  const [tier, settier] = useState('');
  const [purchasingChannel, setpurchasingChannel] = useState('');
  const [billingCycle, setbillingCycle] = useState('');
  const [premierOnboardingSource, setpremierOnboardingSource] = useState('');
  const [premierOnboardingPaymentStatus, setpremierOnboardingPaymentStatus] =
    useState('');
  const [memberType, setmemberType] = useState('');
  const [closeOnOwnLead, setcloseOnOwnLead] = useState(false);
  const [salesforceStatus, setSalesforceStatus] = useState('');
  const [isNew, setisNew] = useState(false);

  const [errorMessage, seterrorMessage] = useState('');
  const [loading, setLoading] = useState(true);

  const { plancodeId } = useParams();
  const history = useHistory();
  const [displayData, setDisplayData] = useState([]);
  const [b2cFreemiumMonthlyUpgrade1, setB2cFreemiumMonthlyUpgrade1] =
    useState('');
  const [b2cFreemiumMonthlyUpgrade2, setB2cFreemiumMonthlyUpgrade2] =
    useState('');
  const [b2cFreemiumMonthlyUpgrade3, setB2cFreemiumMonthlyUpgrade3] =
    useState('');
  const [b2cFreemiumAnnualUpgrade1, setB2cFreemiumAnnualUpgrade1] =
    useState('');
  const [b2cFreemiumAnnualUpgrade2, setB2cFreemiumAnnualUpgrade2] =
    useState('');
  const [b2cFreemiumAnnualUpgrade3, setB2cFreemiumAnnualUpgrade3] =
    useState('');

  useEffect(() => {
    if (plancodeId) {
      getRecurlyPlanCall(plancodeId).then((result) => {
        console.log(result);
        if (result.data && result.data.getAdminConsoleRecurlyPlan) {
          const recurlyPlan = result.data.getAdminConsoleRecurlyPlan;

          if (recurlyPlan.recurlyPlanCode) {
            setrecurlyPlanCode(recurlyPlan.recurlyPlanCode);
          }
          if (recurlyPlan.recurlyPlanCodeLabel) {
            setrecurlyPlanCodeLabel(recurlyPlan.recurlyPlanCodeLabel);
          }
          if (recurlyPlan.accountType) {
            setaccountType(recurlyPlan.accountType);
          }
          if (
            recurlyPlan.recurlyPlanCodePrice &&
            !isNaN(recurlyPlan.recurlyPlanCodePrice)
          ) {
            setrecurlyPlanCodePrice(recurlyPlan.recurlyPlanCodePrice);
          }
          if (recurlyPlan.recurlyPlanCodeTerm) {
            setrecurlyPlanCodeTerm(recurlyPlan.recurlyPlanCodeTerm);
          }
          if (
            recurlyPlan.lengthOfTrialInDays ||
            recurlyPlan.lengthOfTrialInDays === 0
          ) {
            setlengthOfTrialInDays(recurlyPlan.lengthOfTrialInDays);
          }
          if (recurlyPlan.boardsCanJoinCount) {
            setboardsCanJoinCount(recurlyPlan.boardsCanJoinCount);
          }

          if (recurlyPlan.boardSeatsAllowedCount) {
            setboardSeatsAllowedCount(recurlyPlan.boardSeatsAllowedCount);
          }
          if (recurlyPlan.isActive == true || recurlyPlan.isActive == false) {
            setisActive(recurlyPlan.isActive);
          }
          if (recurlyPlan.isPrivate == true || recurlyPlan.isPrivate == false) {
            setisPrivate(recurlyPlan.isPrivate);
          }
          if (
            recurlyPlan.meetingsEnabled == true ||
            recurlyPlan.meetingsEnabled == false
          ) {
            setmeetingsEnabled(recurlyPlan.meetingsEnabled);
          }
          if (recurlyPlan.onboardingRedirectUrl) {
            setonboardingRedirectUrl(recurlyPlan.onboardingRedirectUrl);
          }
          if (recurlyPlan.upgradeUrl) {
            setupgradeUrl(recurlyPlan.upgradeUrl);
          }
          if (recurlyPlan.maxBoardsUpgradeUrl) {
            setMaxBoardsUpgradeUrl(recurlyPlan.maxBoardsUpgradeUrl);
          }
          if (recurlyPlan.specialOffersUrl) {
            setSpecialOffersUrl(recurlyPlan.specialOffersUrl);
          }
          if (recurlyPlan.checkoutRedirectUrl) {
            setcheckoutRedirectUrl(recurlyPlan.checkoutRedirectUrl);
          }
          if (recurlyPlan.dualRecurlyAction) {
            setdualRecurlyAction(recurlyPlan.dualRecurlyAction);
          }
          if (
            recurlyPlan.isDualCapable == true ||
            recurlyPlan.isDualCapable == false
          ) {
            setisDualCapable(recurlyPlan.isDualCapable);
          }
          if (recurlyPlan.isLegacy == true || recurlyPlan.isLegacy == false) {
            setisLegacy(recurlyPlan.isLegacy);
          }
          if (recurlyPlan.earlyConversionAndUpgradePlancode) {
            setearlyConversionAndUpgradePlancode(
              recurlyPlan.earlyConversionAndUpgradePlancode
            );
          }
          if (
            recurlyPlan.isFreemium == true ||
            recurlyPlan.isFreemium == false
          ) {
            setisFreemium(recurlyPlan.isFreemium);
          }
          if (recurlyPlan.freemiumUpgradeOne) {
            setfreemiumUpgradeOne(recurlyPlan.freemiumUpgradeOne);
          }
          if (recurlyPlan.freemiumUpgradeTwo) {
            setfreemiumUpgradeTwo(recurlyPlan.freemiumUpgradeTwo);
          }
          if (recurlyPlan.isAcPlus == true || recurlyPlan.isAcPlus == false) {
            setisAcPlus(recurlyPlan.isAcPlus);
          }
          if (recurlyPlan.b2bFreeTrialMonthlyUpgrade1) {
            setb2bFreeTrialMonthlyUpgrade1(
              recurlyPlan.b2bFreeTrialMonthlyUpgrade1
            );
          }
          if (recurlyPlan.b2bFreeTrialAnnualUpgrade1) {
            setb2bFreeTrialAnnualUpgrade1(
              recurlyPlan.b2bFreeTrialAnnualUpgrade1
            );
          }
          if (recurlyPlan.b2bFreeTrialMonthlyUpgrade2) {
            setb2bFreeTrialMonthlyUpgrade2(
              recurlyPlan.b2bFreeTrialMonthlyUpgrade2
            );
          }
          if (recurlyPlan.b2bFreeTrialAnnualUpgrade2) {
            setb2bFreeTrialAnnualUpgrade2(
              recurlyPlan.b2bFreeTrialAnnualUpgrade2
            );
          }
          if (recurlyPlan.acPlusUpgradeOptionOne) {
            setacPlusUpgradeOptionOne(recurlyPlan.acPlusUpgradeOptionOne);
          }
          if (recurlyPlan.acPlusUpgradeOptionTwo) {
            setacPlusUpgradeOptionTwo(recurlyPlan.acPlusUpgradeOptionTwo);
          }
          if (recurlyPlan.b2bOnboardingCalendar) {
            setb2bOnboardingCalendar(recurlyPlan.b2bOnboardingCalendar);
          }
          if (
            recurlyPlan.isPoeEligible == true ||
            recurlyPlan.isPoeEligible == false
          ) {
            setisPoeEligible(recurlyPlan.isPoeEligible);
          }
          if (recurlyPlan.isUpgrade == true || recurlyPlan.isUpgrade == false) {
            setisUpgrade(recurlyPlan.isUpgrade);
          }
          if (recurlyPlan.legacyPlanCode) {
            setlegacyPlanCode(recurlyPlan.legacyPlanCode);
          }
          if (recurlyPlan.recurlyAction) {
            setrecurlyAction(recurlyPlan.recurlyAction);
          }
          if (recurlyPlan.recurlyPlanCodeDisplayName) {
            setrecurlyPlanCodeDisplayName(
              recurlyPlan.recurlyPlanCodeDisplayName
            );
          }
          if (recurlyPlan.b2cFreemiumMonthlyUpgrade1) {
            setB2cFreemiumMonthlyUpgrade1(
              recurlyPlan.b2cFreemiumMonthlyUpgrade1
            );
          }
          if (recurlyPlan.b2cFreemiumAnnualUpgrade2) {
            setB2cFreemiumMonthlyUpgrade2(
              recurlyPlan.b2cFreemiumMonthlyUpgrade2
            );
          }
          if (recurlyPlan.b2cFreemiumMonthlyUpgrade3) {
            setB2cFreemiumMonthlyUpgrade3(
              recurlyPlan.b2cFreemiumMonthlyUpgrade3
            );
          }
          if (recurlyPlan.b2cFreemiumAnnualUpgrade1) {
            setB2cFreemiumAnnualUpgrade1(recurlyPlan.b2cFreemiumAnnualUpgrade1);
          }
          if (recurlyPlan.b2cFreemiumAnnualUpgrade2) {
            setB2cFreemiumAnnualUpgrade2(recurlyPlan.b2cFreemiumAnnualUpgrade2);
          }
          if (recurlyPlan.b2cFreemiumAnnualUpgrade3) {
            setB2cFreemiumAnnualUpgrade3(recurlyPlan.b2cFreemiumAnnualUpgrade3);
          }

          if (recurlyPlan.salesforceValues) {
            setsalesforceValues(recurlyPlan.salesforceValues);
            setpremierOnboardingExperience(
              recurlyPlan.salesforceValues.premierOnboardingExperience
            );
            setpremierOnboardingPurchaseDate(
              recurlyPlan.salesforceValues.premierOnboardingPurchaseDate
            );
            settier(recurlyPlan.salesforceValues.tier);
            setpurchasingChannel(
              recurlyPlan.salesforceValues.purchasingChannel
            );
            setbillingCycle(recurlyPlan.salesforceValues.billingCycle);
            setpremierOnboardingSource(
              recurlyPlan.salesforceValues.premierOnboardingSource
            );
            setmemberType(recurlyPlan.salesforceValues.memberType);
            setpremierOnboardingPaymentStatus(
              recurlyPlan.salesforceValues.premierOnboardingPaymentStatus
            );
            if (recurlyPlan.salesforceValues.closeOnOwnLead) {
              setcloseOnOwnLead(recurlyPlan.salesforceValues.closeOnOwnLead);
            }
            setSalesforceStatus(recurlyPlan.salesforceValues.status);
          }
        }
        setLoading(false);
      });
    } else {
      setisNew(true);
      setLoading(false);
    }

    async function fetchData() {
      const plansResponse = await getAllRecurlyPlans();
      if (plansResponse.data && plansResponse.data.getRecurlyPlans) {
        setDisplayData(
          plansResponse.data.getRecurlyPlans.sort(function (a, b) {
            if (a.recurlyPlanCode < b.recurlyPlanCode) {
              return -1;
            }
            if (a.recurlyPlanCode > b.recurlyPlanCode) {
              return 1;
            }
            return 0;
          })
        );
      }
    }

    fetchData();
  }, []);

  function handleSubmit() {
    const plancode = {};

    if (recurlyPlanCode) {
      plancode.recurlyPlanCode = recurlyPlanCode;
    }
    if (recurlyPlanCodeLabel) {
      plancode.recurlyPlanCodeLabel = recurlyPlanCodeLabel;
    }
    if (accountType) {
      plancode.accountType = accountType;
    }
    if (recurlyPlanCodePrice && !isNaN(recurlyPlanCodePrice)) {
      plancode.recurlyPlanCodePrice = Number(recurlyPlanCodePrice);
    }
    if (recurlyPlanCodeTerm) {
      plancode.recurlyPlanCodeTerm = recurlyPlanCodeTerm;
    }
    if (lengthOfTrialInDays || lengthOfTrialInDays === 0) {
      plancode.lengthOfTrialInDays = lengthOfTrialInDays;
    }
    if (boardsCanJoinCount) {
      plancode.boardsCanJoinCount = boardsCanJoinCount;
    }
    if (boardSeatsAllowedCount) {
      plancode.boardSeatsAllowedCount = boardSeatsAllowedCount;
    }
    if (isActive == true || isActive == false) {
      plancode.isActive = isActive;
    }
    if (isPrivate == true || isPrivate == false) {
      plancode.isPrivate = isPrivate;
    }
    if (meetingsEnabled == true || meetingsEnabled == false) {
      plancode.meetingsEnabled = meetingsEnabled;
    }
    if (onboardingRedirectUrl) {
      plancode.onboardingRedirectUrl = onboardingRedirectUrl;
    }
    if (upgradeUrl) {
      plancode.upgradeUrl = upgradeUrl;
    }
    if (maxBoardsUpgradeUrl) {
      plancode.maxBoardsUpgradeUrl = maxBoardsUpgradeUrl;
    }
    if (specialOffersUrl) {
      plancode.specialOffersUrl = specialOffersUrl;
    }
    if (checkoutRedirectUrl) {
      plancode.checkoutRedirectUrl = checkoutRedirectUrl;
    }
    if (dualRecurlyAction) {
      plancode.dualRecurlyAction = dualRecurlyAction;
    }
    if (isDualCapable == true || isDualCapable == false) {
      plancode.isDualCapable = isDualCapable;
    }
    if (isLegacy == true || isLegacy == false) {
      plancode.isLegacy = isLegacy;
    }
    if (isFreemium == true || isFreemium == false) {
      plancode.isFreemium = isFreemium;
    }
    if (earlyConversionAndUpgradePlancode) {
      plancode.earlyConversionAndUpgradePlancode =
        earlyConversionAndUpgradePlancode;
    }
    if (freemiumUpgradeOne) {
      plancode.freemiumUpgradeOne = freemiumUpgradeOne;
    }
    if (freemiumUpgradeTwo) {
      plancode.freemiumUpgradeTwo = freemiumUpgradeTwo;
    }
    if (isAcPlus == true || isAcPlus == false) {
      plancode.isAcPlus = isAcPlus;
    }
    if (b2bFreeTrialMonthlyUpgrade1) {
      plancode.b2bFreeTrialMonthlyUpgrade1 = b2bFreeTrialMonthlyUpgrade1;
    }
    if (b2bFreeTrialAnnualUpgrade1) {
      plancode.b2bFreeTrialAnnualUpgrade1 = b2bFreeTrialAnnualUpgrade1;
    }
    if (b2bFreeTrialMonthlyUpgrade2) {
      plancode.b2bFreeTrialMonthlyUpgrade2 = b2bFreeTrialMonthlyUpgrade2;
    }
    if (b2bFreeTrialAnnualUpgrade2) {
      plancode.b2bFreeTrialAnnualUpgrade2 = b2bFreeTrialAnnualUpgrade2;
    }
    if (acPlusUpgradeOptionOne) {
      plancode.acPlusUpgradeOptionOne = acPlusUpgradeOptionOne;
    }
    if (acPlusUpgradeOptionTwo) {
      plancode.acPlusUpgradeOptionTwo = acPlusUpgradeOptionTwo;
    }
    if (b2bOnboardingCalendar) {
      plancode.b2bOnboardingCalendar = b2bOnboardingCalendar;
    } else {
      plancode.deleteb2bOnboardingCalendar = true;
    }
    if (isPoeEligible == true || isPoeEligible == false) {
      plancode.isPoeEligible = isPoeEligible;
    }
    if (isUpgrade == true || isUpgrade == false) {
      plancode.isUpgrade = isUpgrade;
    }
    if (legacyPlanCode) {
      plancode.legacyPlanCode = legacyPlanCode;
    }
    if (recurlyAction) {
      plancode.recurlyAction = recurlyAction;
    }
    if (recurlyPlanCodeDisplayName) {
      plancode.recurlyPlanCodeDisplayName = recurlyPlanCodeDisplayName;
    }
    if (b2cFreemiumMonthlyUpgrade1) {
      plancode.b2cFreemiumMonthlyUpgrade1 = b2cFreemiumMonthlyUpgrade1;
    }
    if (b2cFreemiumMonthlyUpgrade2) {
      plancode.b2cFreemiumMonthlyUpgrade2 = b2cFreemiumMonthlyUpgrade2;
    }
    if (b2cFreemiumMonthlyUpgrade3) {
      plancode.b2cFreemiumMonthlyUpgrade3 = b2cFreemiumMonthlyUpgrade3;
    }
    if (b2cFreemiumAnnualUpgrade1) {
      plancode.b2cFreemiumAnnualUpgrade1 = b2cFreemiumAnnualUpgrade1;
    }
    if (b2cFreemiumAnnualUpgrade2) {
      plancode.b2cFreemiumAnnualUpgrade2 = b2cFreemiumAnnualUpgrade2;
    }
    if (b2cFreemiumAnnualUpgrade3) {
      plancode.b2cFreemiumAnnualUpgrade3 = b2cFreemiumAnnualUpgrade3;
    }

    if (salesforceValues) {
      plancode.salesforceValues = {};
      if (premierOnboardingExperience) {
        plancode.salesforceValues.premierOnboardingExperience =
          premierOnboardingExperience;
      }
      if (premierOnboardingPurchaseDate) {
        plancode.salesforceValues.premierOnboardingPurchaseDate =
          premierOnboardingPurchaseDate;
      }
      if (tier) {
        plancode.salesforceValues.tier = tier;
      }
      if (purchasingChannel) {
        plancode.salesforceValues.purchasingChannel = purchasingChannel;
      }
      if (billingCycle) {
        plancode.salesforceValues.billingCycle = billingCycle;
      }
      if (premierOnboardingSource) {
        plancode.salesforceValues.premierOnboardingSource =
          premierOnboardingSource;
      }
      if (memberType) {
        plancode.salesforceValues.memberType = memberType;
      }
      if (premierOnboardingPaymentStatus) {
        plancode.salesforceValues.premierOnboardingPaymentStatus =
          premierOnboardingPaymentStatus;
      }
      if (closeOnOwnLead) {
        plancode.salesforceValues.closeOnOwnLead = closeOnOwnLead;
      }
      if (premierOnboardingPaymentStatus) {
        plancode.salesforceValues.status = salesforceStatus;
      }
    }
    console.log(plancode);
    updateRecurlyPlanCall(plancode)
      .then((result) => {
        back();
      })
      .catch((err) => {
        console.log(err);
        seterrorMessage(err.errors[0].message);
      });
  }

  function back() {
    history.push('/plan-mapping-table');
  }

  if (loading) {
    return (
      <div style={classes.loadingContainer}>
        <LoadingIndicatorLogo iconFontSize={15} iconRight={13} />
      </div>
    );
  }

  return (
    <div style={classes.appContainer}>
      <div style={classes.header}>
        <Typography variant="h3">
          <Button variant="outlined" onClick={back} style={{ marginRight: 10 }}>
            Back
          </Button>
          Edit PlanCode
        </Typography>
      </div>
      <div>
        <Typography variant="h4" style={{ marginBottom: 20 }}>
          General
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              sx={classes.formField}
              id="outlined-multiline-flexible"
              label="recurlyPlanCode"
              fullWidth
              required
              value={recurlyPlanCode}
              disabled={!isNew}
              onChange={(event) => setrecurlyPlanCode(event.target.value)}
            />
            <TextField
              sx={classes.formField}
              id="outlined-multiline-flexible"
              label="recurlyPlanCodeLabel"
              fullWidth
              required
              value={recurlyPlanCodeLabel}
              onChange={(event) => setrecurlyPlanCodeLabel(event.target.value)}
            />
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                accountType *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={accountType}
                label="accountType *"
                onChange={(event) => setaccountType(event.target.value)}
              >
                <MenuItem value={''}></MenuItem>
                <MenuItem value={'B2B'}>B2B</MenuItem>
                <MenuItem value={'B2C'}>B2C</MenuItem>
              </Select>
            </FormControl>
            <TextField
              sx={classes.formField}
              id="outlined-multiline-flexible"
              label="recurlyPlanCodePrice"
              fullWidth
              value={recurlyPlanCodePrice}
              type="number"
              onChange={(event) => setrecurlyPlanCodePrice(event.target.value)}
            />

            <TextField
              sx={classes.formField}
              id="outlined-multiline-flexible"
              label="lengthOfTrialInDays"
              fullWidth
              required
              type="number"
              value={lengthOfTrialInDays}
              onChange={(event) => setlengthOfTrialInDays(event.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              sx={classes.formField}
              id="outlined-multiline-flexible"
              label="onboardingRedirectUrl"
              fullWidth
              required
              value={onboardingRedirectUrl}
              onChange={(event) => setonboardingRedirectUrl(event.target.value)}
            />
            <TextField
              sx={classes.formField}
              id="outlined-multiline-flexible"
              label="checkoutRedirectUrl"
              fullWidth
              value={checkoutRedirectUrl}
              onChange={(event) => setcheckoutRedirectUrl(event.target.value)}
            />
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                recurlyPlanCodeDisplayName
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={recurlyPlanCodeDisplayName}
                label="recurlyPlanCodeDisplayName"
                onChange={(event) =>
                  setrecurlyPlanCodeDisplayName(event.target.value)
                }
              >
                <MenuItem value={''}></MenuItem>
                <MenuItem value={'Enterprise'}>Enterprise</MenuItem>
                <MenuItem value={'Team'}>Team</MenuItem>
                <MenuItem value={'Small Business'}>Small Business</MenuItem>
                <MenuItem value={'Executive'}>Executive</MenuItem>
                <MenuItem value={'Startup'}>Startup</MenuItem>
                <MenuItem value={'Custom'}>Custom</MenuItem>
                <MenuItem value={'Premier'}>Premier</MenuItem>
                <MenuItem value={'Essentials'}>Essentials</MenuItem>
                <MenuItem value={'Starter'}>Starter</MenuItem>
                <MenuItem value={'Professional'}>Professional</MenuItem>
                <MenuItem value={'Executive Plus'}>Executive Plus</MenuItem>
                <MenuItem value={'Growth'}>Growth</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                recurlyPlanCodeTerm
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={recurlyPlanCodeTerm}
                label="recurlyPlanCodeTerm"
                onChange={(event) => setrecurlyPlanCodeTerm(event.target.value)}
              >
                <MenuItem value={''}></MenuItem>
                <MenuItem value={'mo'}>mo</MenuItem>
                <MenuItem value={'yr'}>yr</MenuItem>
                <MenuItem value={'qtr'}>qtr</MenuItem>
                <MenuItem value={'None'}>None</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              sx={classes.checkField}
              control={
                <Checkbox
                  sx={classes.formField}
                  id="outlined-multiline-flexible"
                  label="isActive"
                  checked={isActive}
                  onChange={(event) => setisActive(event.target.checked)}
                />
              }
              label="isActive"
            />
            <FormControl>
              <FormControlLabel
                sx={classes.checkField}
                control={
                  <Checkbox
                    sx={classes.formField}
                    id="outlined-multiline-flexible"
                    label="isFreemium"
                    checked={isFreemium}
                    onChange={(event) => setisFreemium(event.target.checked)}
                  />
                }
                label="isFreemium"
              />
            </FormControl>
          </Grid>
        </Grid>

        <Typography variant="h4" style={{ marginBottom: 20 }}>
          B2C
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                boardsCanJoinCount
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={boardsCanJoinCount}
                label="boardsCanJoinCount"
                onChange={(event) => setboardsCanJoinCount(event.target.value)}
              >
                <MenuItem value={''}></MenuItem>
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={-1}>Unlimited</MenuItem>
              </Select>
            </FormControl>
            <TextField
              sx={classes.formField}
              id="outlined-multiline-flexible"
              label="upgradeUrl"
              fullWidth
              value={upgradeUrl}
              onChange={(event) => setupgradeUrl(event.target.value)}
            />
            <TextField
              sx={classes.formField}
              id="outlined-multiline-flexible"
              label="maxBoardsUpgradeUrl"
              fullWidth
              value={maxBoardsUpgradeUrl}
              onChange={(event) => setMaxBoardsUpgradeUrl(event.target.value)}
            />
            <TextField
              sx={classes.formField}
              id="outlined-multiline-flexible"
              label="specialOffersUrl"
              fullWidth
              value={specialOffersUrl}
              onChange={(event) => setSpecialOffersUrl(event.target.value)}
            />
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                earlyConversionAndUpgradePlancode
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={earlyConversionAndUpgradePlancode}
                label="earlyConversionAndUpgradePlancode"
                onChange={(event) =>
                  setearlyConversionAndUpgradePlancode(event.target.value)
                }
              >
                {displayData.map((row) => (
                  <MenuItem value={row.recurlyPlanCode}>
                    {row.recurlyPlanCode} - {row.recurlyPlanCodeLabel}
                  </MenuItem>
                ))}
                <MenuItem value={'PAID'}>PAID</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              sx={classes.checkField}
              control={
                <Checkbox
                  sx={classes.formField}
                  id="outlined-multiline-flexible"
                  label="meetingsEnabled"
                  checked={meetingsEnabled}
                  onChange={(event) => setmeetingsEnabled(event.target.checked)}
                />
              }
              label="meetingsEnabled"
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                b2cFreemiumMonthlyUpgrade1
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={b2cFreemiumMonthlyUpgrade1}
                label="b2cFreemiumMonthlyUpgrade1"
                onChange={(event) =>
                  setB2cFreemiumMonthlyUpgrade1(event.target.value)
                }
              >
                {displayData.map((row) => (
                  <MenuItem value={row.recurlyPlanCode}>
                    {row.recurlyPlanCode} - {row.recurlyPlanCodeLabel}
                  </MenuItem>
                ))}
                <MenuItem value={'PAID'}>PAID</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                b2cFreemiumMonthlyUpgrade2
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={b2cFreemiumMonthlyUpgrade2}
                label="b2cFreemiumMonthlyUpgrade2"
                onChange={(event) =>
                  setB2cFreemiumMonthlyUpgrade2(event.target.value)
                }
              >
                {displayData.map((row) => (
                  <MenuItem value={row.recurlyPlanCode}>
                    {row.recurlyPlanCode} - {row.recurlyPlanCodeLabel}
                  </MenuItem>
                ))}
                <MenuItem value={'PAID'}>PAID</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                b2cFreemiumMonthlyUpgrade3
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={b2cFreemiumMonthlyUpgrade3}
                label="b2cFreemiumMonthlyUpgrade3"
                onChange={(event) =>
                  setB2cFreemiumMonthlyUpgrade3(event.target.value)
                }
              >
                {displayData.map((row) => (
                  <MenuItem value={row.recurlyPlanCode}>
                    {row.recurlyPlanCode} - {row.recurlyPlanCodeLabel}
                  </MenuItem>
                ))}
                <MenuItem value={'PAID'}>PAID</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                b2cFreemiumAnnualUpgrade1
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={b2cFreemiumAnnualUpgrade1}
                label="b2cFreemiumAnnualUpgrade1"
                onChange={(event) =>
                  setB2cFreemiumAnnualUpgrade1(event.target.value)
                }
              >
                {displayData.map((row) => (
                  <MenuItem value={row.recurlyPlanCode}>
                    {row.recurlyPlanCode} - {row.recurlyPlanCodeLabel}
                  </MenuItem>
                ))}
                <MenuItem value={'PAID'}>PAID</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                b2cFreemiumAnnualUpgrade2
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={b2cFreemiumAnnualUpgrade2}
                label="b2cFreemiumAnnualUpgrade2"
                onChange={(event) =>
                  setB2cFreemiumAnnualUpgrade2(event.target.value)
                }
              >
                {displayData.map((row) => (
                  <MenuItem value={row.recurlyPlanCode}>
                    {row.recurlyPlanCode} - {row.recurlyPlanCodeLabel}
                  </MenuItem>
                ))}
                <MenuItem value={'PAID'}>PAID</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                b2cFreemiumAnnualUpgrade3
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={b2cFreemiumAnnualUpgrade3}
                label="b2cFreemiumAnnualUpgrade3"
                onChange={(event) =>
                  setB2cFreemiumAnnualUpgrade3(event.target.value)
                }
              >
                {displayData.map((row) => (
                  <MenuItem value={row.recurlyPlanCode}>
                    {row.recurlyPlanCode} - {row.recurlyPlanCodeLabel}
                  </MenuItem>
                ))}
                <MenuItem value={'PAID'}>PAID</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Typography variant="h4" style={{ marginBottom: 20 }}>
          B2B
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                boardSeatsAllowedCount
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={boardSeatsAllowedCount}
                label="boardSeatsAllowedCount"
                onChange={(event) =>
                  setboardSeatsAllowedCount(event.target.value)
                }
              >
                <MenuItem value={''}></MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={-1}>Unlimited</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                freemiumUpgradeOne
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={freemiumUpgradeOne}
                label="freemiumUpgradeOne"
                onChange={(event) => setfreemiumUpgradeOne(event.target.value)}
              >
                {displayData.map((row) => (
                  <MenuItem value={row.recurlyPlanCode}>
                    {row.recurlyPlanCode} - {row.recurlyPlanCodeLabel}
                  </MenuItem>
                ))}
                <MenuItem value={'PAID'}>PAID</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                freemiumUpgradeTwo
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={freemiumUpgradeTwo}
                label="freemiumUpgradeTwo"
                onChange={(event) => setfreemiumUpgradeTwo(event.target.value)}
              >
                {displayData.map((row) => (
                  <MenuItem value={row.recurlyPlanCode}>
                    {row.recurlyPlanCode} - {row.recurlyPlanCodeLabel}
                  </MenuItem>
                ))}
                <MenuItem value={'PAID'}>PAID</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                b2bFreeTrialMonthlyUpgrade1
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={b2bFreeTrialMonthlyUpgrade1}
                label="b2bFreeTrialMonthlyUpgrade1"
                onChange={(event) =>
                  setb2bFreeTrialMonthlyUpgrade1(event.target.value)
                }
              >
                {displayData.map((row) => (
                  <MenuItem value={row.recurlyPlanCode}>
                    {row.recurlyPlanCode} - {row.recurlyPlanCodeLabel}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                b2bFreeTrialAnnualUpgrade1
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={b2bFreeTrialAnnualUpgrade1}
                label="b2bFreeTrialAnnualUpgrade1"
                onChange={(event) =>
                  setb2bFreeTrialAnnualUpgrade1(event.target.value)
                }
              >
                {displayData.map((row) => (
                  <MenuItem value={row.recurlyPlanCode}>
                    {row.recurlyPlanCode} - {row.recurlyPlanCodeLabel}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              sx={classes.checkField}
              control={
                <Checkbox
                  sx={classes.formField}
                  id="outlined-multiline-flexible"
                  label="isPrivate"
                  checked={isPrivate}
                  onChange={(event) => setisPrivate(event.target.checked)}
                />
              }
              label="isPrivate"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              sx={classes.formField}
              id="outlined-multiline-flexible"
              label="B2B Onboarding Calendar"
              fullWidth
              value={b2bOnboardingCalendar}
              onChange={(event) => setb2bOnboardingCalendar(event.target.value)}
            />

            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                acPlusUpgradeOptionOne
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={acPlusUpgradeOptionOne}
                label="acPlusUpgradeOptionOne"
                onChange={(event) =>
                  setacPlusUpgradeOptionOne(event.target.value)
                }
              >
                {displayData.map((row) => (
                  <MenuItem value={row.recurlyPlanCode}>
                    {row.recurlyPlanCode} - {row.recurlyPlanCodeLabel}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                acPlusUpgradeOptionTwo
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={acPlusUpgradeOptionTwo}
                label="acPlusUpgradeOptionTwo"
                onChange={(event) =>
                  setacPlusUpgradeOptionTwo(event.target.value)
                }
              >
                {displayData.map((row) => (
                  <MenuItem value={row.recurlyPlanCode}>
                    {row.recurlyPlanCode} - {row.recurlyPlanCodeLabel}
                  </MenuItem>
                ))}
                <MenuItem value={'PAID'}>PAID</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                b2bFreeTrialMonthlyUpgrade2
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={b2bFreeTrialMonthlyUpgrade2}
                label="b2bFreeTrialMonthlyUpgrade2"
                onChange={(event) =>
                  setb2bFreeTrialMonthlyUpgrade2(event.target.value)
                }
              >
                {displayData.map((row) => (
                  <MenuItem value={row.recurlyPlanCode}>
                    {row.recurlyPlanCode} - {row.recurlyPlanCodeLabel}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                b2bFreeTrialAnnualUpgrade2
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={b2bFreeTrialAnnualUpgrade2}
                label="b2bFreeTrialAnnualUpgrade2"
                onChange={(event) =>
                  setb2bFreeTrialAnnualUpgrade2(event.target.value)
                }
              >
                {displayData.map((row) => (
                  <MenuItem value={row.recurlyPlanCode}>
                    {row.recurlyPlanCode} - {row.recurlyPlanCodeLabel}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <FormControlLabel
                sx={classes.checkField}
                control={
                  <Checkbox
                    sx={classes.formField}
                    id="outlined-multiline-flexible"
                    label="isAcPlus"
                    checked={isAcPlus}
                    onChange={(event) => setisAcPlus(event.target.checked)}
                  />
                }
                label="isAcPlus"
              />
            </FormControl>
          </Grid>
        </Grid>

        <Typography variant="h4" style={{ marginBottom: 20 }}>
          Salesforce
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                premierOnboardingExperience
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={premierOnboardingExperience}
                label="premierOnboardingExperience"
                onChange={(event) =>
                  setpremierOnboardingExperience(event.target.value)
                }
              >
                <MenuItem value={''}></MenuItem>
                <MenuItem value={'YES_AT_CHECKOUT'}>Yes at Checkout</MenuItem>
                <MenuItem value={'YES'}>Yes</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                premierOnboardingPurchaseDate
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={premierOnboardingPurchaseDate}
                label="premierOnboardingPurchaseDate"
                onChange={(event) =>
                  setpremierOnboardingPurchaseDate(event.target.value)
                }
              >
                <MenuItem value={''}></MenuItem>
                <MenuItem value={'TODAY_AT_CHECKOUT'}>
                  Today at Checkout
                </MenuItem>
                <MenuItem value={'TODAY'}>Today</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">tier</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={tier}
                label="tier"
                onChange={(event) => settier(event.target.value)}
              >
                <MenuItem value={''}></MenuItem>
                <MenuItem value={'Small Business'}>Small Business</MenuItem>
                <MenuItem value={'Starter'}>Starter</MenuItem>
                <MenuItem value={'Professional'}>Professional</MenuItem>
                <MenuItem value={'Premier'}>Premier</MenuItem>
                <MenuItem value={'Essentials'}>Essentials</MenuItem>
                <MenuItem value={'VIP'}>VIP</MenuItem>
                <MenuItem value={'Executive'}>Executive</MenuItem>
                <MenuItem value={'Executive Plus'}>Executive Plus</MenuItem>
                <MenuItem value={'Freemium'}>Freemium</MenuItem>
                <MenuItem value={'Dual'}>Dual</MenuItem>
                <MenuItem value={'AdvisoryCloud Plus'}>
                  AdvisoryCloud Plus
                </MenuItem>
                <MenuItem value={'Crowdsourced'}>Crowdsourced</MenuItem>
                <MenuItem value={'Traditional'}>Traditional</MenuItem>
                <MenuItem value={'Startup'}>Startup</MenuItem>
                <MenuItem value={'Non-Profit'}>Non-Profit</MenuItem>
                <MenuItem value={'PAB'}>PAB</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                purchasingChannel *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={purchasingChannel}
                label="purchasingChannel"
                onChange={(event) => setpurchasingChannel(event.target.value)}
              >
                <MenuItem value={'None'}>None</MenuItem>
                <MenuItem value={'Close on Own'}>Close on Own</MenuItem>
                <MenuItem value={'Close on Call'}>Close on Call</MenuItem>
                <MenuItem value={'Close After Contact'}>
                  Close After Contact
                </MenuItem>
                <MenuItem value={'Company Invitee'}>Company Invitee</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                billingCycle
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={billingCycle}
                label="billingCycle"
                onChange={(event) => setbillingCycle(event.target.value)}
              >
                <MenuItem value={''}></MenuItem>
                <MenuItem value={'Monthly'}>Monthly</MenuItem>
                <MenuItem value={'Quarterly'}>Quarterly</MenuItem>
                <MenuItem value={'Annual'}>Annual</MenuItem>
                <MenuItem value={'Complimentary'}>Complimentary</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                premierOnboardingSource
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={premierOnboardingSource}
                label="premierOnboardingSource"
                onChange={(event) =>
                  setpremierOnboardingSource(event.target.value)
                }
              >
                <MenuItem value={''}></MenuItem>
                <MenuItem value={'SALES_AT_CHECKOUT'}>
                  Sales at Checkout
                </MenuItem>
                <MenuItem value={'INCLUDED'}>Included</MenuItem>
                <MenuItem value={'CoO_AT_CHECKOUT'}>COO at Checkout</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                memberType *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={memberType}
                label="memberType"
                onChange={(event) => setmemberType(event.target.value)}
              >
                <MenuItem value={''}></MenuItem>
                <MenuItem value={'Company'}>Company</MenuItem>
                <MenuItem value={'Premium Monthly Membership'}>
                  Premium Monthly Membership
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                premierOnboardingPaymentStatus *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={premierOnboardingPaymentStatus}
                label="premierOnboardingPaymentStatus"
                onChange={(event) =>
                  setpremierOnboardingPaymentStatus(event.target.value)
                }
              >
                <MenuItem value={'None'}>None</MenuItem>
                <MenuItem value={'STATUS_AT_CHECKOUT'}>
                  STATUS_AT_CHECKOUT
                </MenuItem>
                <MenuItem value={'PAID'}>PAID</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">status *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={salesforceStatus}
                label="status"
                onChange={(event) => setSalesforceStatus(event.target.value)}
              >
                <MenuItem value={''}></MenuItem>
                <MenuItem value={'Client'}>Client</MenuItem>
                <MenuItem value={'Company'}>Company</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              sx={classes.checkField}
              control={
                <Checkbox
                  sx={classes.formField}
                  id="outlined-multiline-flexible"
                  label="closeOnOwnLead"
                  checked={closeOnOwnLead}
                  onChange={(event) => setcloseOnOwnLead(event.target.checked)}
                />
              }
              label="closeOnOwnLead"
            />
          </Grid>
        </Grid>

        <Typography variant="h4" style={{ marginBottom: 20 }}>
          Misc
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                dualRecurlyAction
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={dualRecurlyAction}
                label="dualRecurlyAction"
                onChange={(event) => setdualRecurlyAction(event.target.value)}
              >
                <MenuItem value={''}></MenuItem>
                <MenuItem value={'ADD_SUBSCRIPTION'}>ADD_SUBSCRIPTION</MenuItem>
                <MenuItem value={'NOTHING'}>NOTHING</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={classes.formField}>
              <InputLabel id="demo-simple-select-label">
                recurlyAction *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                value={recurlyAction}
                label="recurlyAction"
                onChange={(event) => setrecurlyAction(event.target.value)}
              >
                <MenuItem value={''}></MenuItem>
                <MenuItem value={'CREATE_ACCOUNT_AND_SUBSCRIPTION'}>
                  CREATE_ACCOUNT_AND_SUBSCRIPTION
                </MenuItem>
                <MenuItem value={'DO_NOTHING'}>DO_NOTHING</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControlLabel
              sx={classes.checkField}
              control={
                <Checkbox
                  sx={classes.formField}
                  id="outlined-multiline-flexible"
                  label="isLegacy"
                  checked={isLegacy}
                  onChange={(event) => setisLegacy(event.target.checked)}
                />
              }
              label="isLegacy"
            />
            <FormControlLabel
              sx={classes.checkField}
              control={
                <Checkbox
                  sx={classes.formField}
                  id="outlined-multiline-flexible"
                  label="isPoeEligible"
                  checked={isPoeEligible}
                  onChange={(event) => setisPoeEligible(event.target.checked)}
                />
              }
              label="isPoeEligible"
            />
            <FormControlLabel
              sx={classes.checkField}
              control={
                <Checkbox
                  sx={classes.formField}
                  id="outlined-multiline-flexible"
                  label="isUpgrade"
                  checked={isUpgrade}
                  onChange={(event) => setisUpgrade(event.target.checked)}
                />
              }
              label="isUpgrade"
            />
            <FormControlLabel
              sx={classes.checkField}
              control={
                <Checkbox
                  sx={classes.formField}
                  id="outlined-multiline-flexible"
                  label="isDualCapable"
                  checked={isDualCapable}
                  onChange={(event) => setisDualCapable(event.target.checked)}
                />
              }
              label="isDualCapable"
            />
          </Grid>
        </Grid>

        {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}
        <Button
          size="large"
          variant="contained"
          onClick={handleSubmit}
          style={{ float: 'right' }}
        >
          SAVE
        </Button>
      </div>
    </div>
  );
}

export default PlanCodeForm;
