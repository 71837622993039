import React, { useState } from 'react';
import {
  useMediaQuery,
  Paper,
  Typography,
  Menu,
  Fade,
  MenuItem,
  Box,
  Dialog,
  InputLabel,
  FormControl,
  Select,
  DialogActions,
  CircularProgress,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, ACIcon } from '@advisorycloud/react-ui-components';
import {
  deleteAdvisorCCPA,
  deleteCompanyCCPA,
} from '../../services/admin_services';
import { AccountType } from '../../services/utils/types';
import AdminUserCardHeader from './AdminUserHeader';
import { updateAdvisorOnboardingStatus } from '../../services/advisor_services/index';
import { updateUserSegment } from '../../services/company_services';

const useStyles = () => ({
  card: {
    backgroundColor: 'white',
    width: '100%',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    borderLeft: '5px solid rgb(0, 104, 173)',
    position: 'relative',
  },
  borderContainer: {
    border: '1px solid rgb(230,237,254)',
    padding: '7px',
    position: 'relative',
    width: '100%',
    height: '100%',
  },

  dialogContainer: {
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  dialogActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
  },
  modalLoadingContainer: {
    width: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
  },
  separator: {
    width: '100%',
    marginTop: '0px',
  },
  separatorLine: {
    width: '100%',
    borderBottom: '1px solid rgb(230,237,254)',
    bottom: '70px',
    left: '0px',
    position: 'absolute',
  },
  postCardBottom: {
    paddingTop: '30px',
    paddingBottom: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  badge: {
    position: 'absolute',
    top: '0px',
    left: '-2px',
    zIndex: 99,
  },
  badgeText: {
    paddingLeft: '5px',
    paddingRight: '5px',
    borderRadius: '0% 0% 10% 0% / 0% 0% 18% 0%',
    fontSize: '0.6rem',
    color: 'white',
    backgroundColor: 'rgb(0, 104, 173)',
    textTransform: 'uppercase',
  },
  leftButtonsContainer: {
    width: 'fit-content',
    display: 'flex',
  },
  rightButtonsContainer: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginBottom: '-10px',
  },
});

const AdminUserCard = (props) => {
  const {
    // Header props
    status,
    firstName,
    lastName,
    userTitle,
    companyName,
    phoneNumber,
    email,
    type,
    id,
    isSystemAdmin,
    accountLevel,
    lastLoginDate,
    registrationDate,
    updateStatus,
    // temporal value
    onboardingStatus,
    // FooterProps
    cardLeftButtons,
    cardRightButtons,
    // format
    headerFormat,
    isModalVisible,
    setIsModalVisible,
    avatarImage,
    boardOwnerMail,
    boardType,
    seatsCount,
    boardTotalSeats,
    planCode,
    advisorNumberOfBoards,
    freeTrialEndsOn,
    editUserDisabled,
    editUser,
    editCompanyDisabled,
    editCompany,
    isSuperAdmin,
    userSegments,
  } = props;
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userSegment, setUserSegment] = useState(userSegments || '');
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [ModalText, setModalText] = useState('');
  // These are not valid anymore, so any will be considerede as incomplete
  const stepValues = ['step1', 'step2', 'step3', 'step4', 'step5', 'step6'];
  const [selectedOnboardingStatus, setSelectedOnboardingStatus] = useState(
    stepValues.includes(onboardingStatus) ? 'inProcess' : onboardingStatus
  );
  const openMenu = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = async (event) => {
    setSelectedOnboardingStatus(event.target.value);
    if (event.target.value === '') return;
    const onboardingProcess =
      event.target.value === 'Complete' ? 'Complete' : 'inProcess';
    await updateAdvisorOnboardingStatus({
      ADVISOR_ID: id,
      ONBOARDING_STATUS: onboardingProcess,
      ONBOARDING_PROCESS: onboardingProcess,
    });
  };

  const handleChangeUserSegment = async (event) => {
    setUserSegment(event.target.value);
    await updateUserSegment({
      COMPANY_ID: id,
      USER_SEGMENT: event.target.value,
    });
  };

  async function deleteUser() {
    try {
      if (type === AccountType.ADVISOR) {
        await deleteAdvisorCCPA({ USER_ID: id, EMAIL: email });
      } else if (type === AccountType.COMPANY) {
        await deleteCompanyCCPA({ COMPANY_ID: id });
      }
      setModalText('User deleted successfully');
      setIsModalLoading(false);
    } catch (error) {
      console.error('Error deleting user:', error);
      if (
        error.errors &&
        error.errors.length > 0 &&
        error.errors[0].message &&
        JSON.parse(error.errors[0].message) &&
        JSON.parse(error.errors[0].message).error
      ) {
        setModalText(
          'Error deleting user' + JSON.parse(error.errors[0].message).error
        );
      } else {
        setModalText('Error deleting user');
      }
      setIsModalLoading(false);
    }
  }

  function renderRightButtons() {
    if (cardRightButtons === null || cardRightButtons === undefined) {
      return null;
    }

    const filteredButtons = cardRightButtons.filter(
      (element) => element.showButton !== false
    );
    return (
      <>
        <Dialog
          open={isModalVisible}
          sx={classes.dialogContainer}
          maxWidth="sm"
          onClose={() => {
            setIsModalVisible(false);
          }}
        >
          <div style={classes.dialogContainer}>
            <DialogContent
              style={{
                padding: 20,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <DialogContentText>{t('ADVISOR-MODAL-CCPA')}</DialogContentText>
              {ModalText && <DialogContentText>{ModalText}</DialogContentText>}
            </DialogContent>
            <DialogActions sx={classes.dialogActions}>
              {isModalLoading ? (
                <div style={classes.modalLoadingContainer}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setIsModalVisible(false);
                    }}
                    style={{
                      paddingTop: isSM ? 30 : 0,
                      paddingBottom: isSM ? 30 : 0,
                      width: 150,
                    }}
                  >
                    {t('CANCEL')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setIsModalLoading(true);
                      deleteUser();
                    }}
                    style={{
                      paddingTop: isSM ? 30 : 0,
                      paddingBottom: isSM ? 30 : 0,
                      width: 150,
                    }}
                  >
                    {t('CONFIRM')}
                  </Button>
                </>
              )}
            </DialogActions>
          </div>
        </Dialog>
        {isSuperAdmin && type === AccountType.COMPANY && (
          <FormControl
            sx={{
              minWidth: '150px',
              marginBottom: '0px',
              marginRight: '10px',
            }}
          >
            <InputLabel id="simple-select-label">User Segments</InputLabel>
            <Select
              labelId="simple-select-label"
              id="simple-select"
              value={userSegment}
              label="User Segments"
              onChange={handleChangeUserSegment}
              sx={{
                height: '45px',
                marginTop: '4px',
                borderRadius: '10px',
              }}
            >
              <MenuItem value="start_your_advisorycloud">
                start_your_advisorycloud
              </MenuItem>
              <MenuItem value="managed_advisory_board">
                managed_advisory_board
              </MenuItem>
              <MenuItem value="insights">insights</MenuItem>
              <MenuItem value="bring_your_own">bring_your_own</MenuItem>
              <MenuItem value="None">None</MenuItem>
            </Select>
          </FormControl>
        )}
        {filteredButtons.map((element, index) => (
          <>
            <div
              key={element.id}
              style={{
                marginLeft: index === 0 ? 0 : '10px',
              }}
            >
              <Button
                color="primary"
                width="fit-content"
                style={{
                  fontSize: '.7rem',
                }}
                onClick={element.menu ? handleClick : element.onClick}
                disabled={element.disabled}
                variant="outlined"
              >
                <ACIcon
                  iconname={element.iconname}
                  style={{
                    fontSize: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 'fit-content',
                    height: 'fit-content',
                    marginBottom: 2,
                  }}
                />
                {element.text}
              </Button>
            </div>
            {element.appendText ? (
              <div
                style={{
                  display: 'inline',
                  color: 'grey',
                  marginLeft: 7,
                  marginRight: 2,
                  paddingTop: 5,
                  marginBottom: 0,
                }}
              >
                {element.appendText}
              </div>
            ) : null}
            {element.menu ? (
              <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                TransitionComponent={Fade}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                // getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {element.options.map((opp) => (
                  <MenuItem
                    onClick={() => {
                      opp.action();
                      handleClose();
                    }}
                  >
                    {opp.text}
                  </MenuItem>
                ))}
              </Menu>
            ) : null}
          </>
        ))}
      </>
    );
  }

  return (
    <Paper elevation={0} sx={classes.card}>
      <div style={classes.badge}>
        <Typography sx={classes.badgeText}>{accountLevel}</Typography>
      </div>
      <div style={classes.borderContainer}>
        <div style={classes.separator}>
          <AdminUserCardHeader
            status={status}
            updateStatus={updateStatus}
            firstName={firstName}
            lastName={lastName}
            userTitle={userTitle}
            id={id}
            companyName={companyName}
            phoneNumber={phoneNumber}
            email={email}
            accountLevel={accountLevel}
            lastLoginDate={lastLoginDate}
            registrationDate={registrationDate}
            headerFormat={headerFormat}
            avatarImage={avatarImage}
            boardOwnerMail={boardOwnerMail}
            boardType={boardType}
            seatsCount={seatsCount}
            boardTotalSeats={boardTotalSeats}
            planCode={planCode}
            advisorNumberOfBoards={advisorNumberOfBoards}
            freeTrialEndsOn={freeTrialEndsOn}
            editUserDisabled={editUserDisabled}
            editUser={editUser}
            editCompanyDisabled={editCompanyDisabled}
            editCompany={editCompany}
            // temporal value
            onboardingStatus={onboardingStatus}
          />
        </div>

        <div style={classes.separatorLine} />

        <div style={classes.postCardBottom}>
          <div style={classes.leftButtonsContainer}>
            {cardLeftButtons !== null || cardLeftButtons !== undefined ? (
              <>
                {cardLeftButtons.map((element, index) => (
                  <div
                    key={element.id}
                    style={{
                      marginLeft: index === 0 ? 0 : '10px',
                    }}
                  >
                    <Button
                      color="primary"
                      width="95px"
                      style={{ fontSize: '.7rem' }}
                      onClick={element.onClick}
                      disabled={element.disabled}
                    >
                      {element.text}
                    </Button>
                  </div>
                ))}
              </>
            ) : null}
          </div>
          <div style={classes.rightButtonsContainer}>
            {(isSystemAdmin || isSuperAdmin) && (
              <Box
                sx={{
                  minWidth: '200px',
                  marginRight: '20px',
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="onboardingStatusLabel">
                    OnboardingStatus
                  </InputLabel>
                  <Select
                    labelId="onboardingStatusLabel"
                    id="onboardingStatus"
                    value={selectedOnboardingStatus}
                    label="OnboardingStatus"
                    sx={{ boderColor: 'blue', height: '35px' }}
                    onChange={handleChange}
                  >
                    <MenuItem value="inProcess">In Process</MenuItem>
                    <MenuItem value="Complete">Complete</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
            {renderRightButtons()}
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default AdminUserCard;
